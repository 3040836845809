var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.service.loopForNames, function (name) {
        return [
          _vm.service.attrMap[name].vIf
            ? _c(
                _vm.service.componentMap[name],
                _vm._g(
                  _vm._b(
                    { key: name, tag: "component" },
                    "component",
                    _vm.service.attrMap[name].$props,
                    false
                  ),
                  _vm.service.attrMap[name].$listeners
                )
              )
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }