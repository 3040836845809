export default {
    PtTenants: {
        switchTenantTitle: '确定切换组织并刷新页面吗？',
        switchTenantTip: '若您有正在编辑的内容，在点击“确定”后将不会被保存。请问是否继续切换组织？',
        ok: '确定',
        cancel: '取消',
        personalTenant: '个人账号',
        domainLimit: '域名限制',
        IPLimit: 'IP限制',
        ssoLimit: 'SSO限制',
        domainLimitTip: '您所在的组织要求您使用商务邮箱访问智慧芽，请通过邮箱登录。',
        IPLimitTip: '请在指定的IP范围内访问该产品。<br>您所在的组织希望您在指定地点（ 如办公室） 访问我们的产品。',
        dingdingTip: '您的管理员要求您通过钉钉的智慧芽应用登录。',
        feishuTip: '您的管理员要求您通过飞书的智慧芽应用登录。',
        wecomTip: '您的管理员要求您通过企业微信的智慧芽应用登录。',
        jwtTip: '您的管理员要求您通过内部认证服务器的智慧芽应用登录。',
        ssoTip: '您的管理员要求您通过SSO方式登录智慧芽产品。请通过您的内部认证服务器来访问智慧芽。',
        switchTenantFailed: '组织切换失败，请联系智慧芽客服人员。',
        accountStatusNotActiveTip: '您的邮箱未设置或未认证，请去用户设置-个人信息中认证邮箱。',
        youWillSwitchedTo: '您即将切换到：{tenantName}。',
        error31623: '对不起，为了您组织的数据安全，采用SSO-JWT对接方式则无法切换组织。',
        error31626: '对不起，您组织要求SSO登录，无法切换到其他组织。',
    },
};
