export default {
    PtTenants: {
        switchTenantTitle: 'Are you sure to switch to the organization and refresh the page?',
        // eslint-disable-next-line max-len
        switchTenantTip: 'If you have content being edited, it will be lost after clicking "Continue". Do you want to continue?',
        ok: 'Continue',
        cancel: 'Cancel',
        personalTenant: 'Personal Account',
        domainLimit: 'Domain Restrictions',
        IPLimit: 'IP Restrictions',
        ssoLimit: 'SSO Restrictions',
        // eslint-disable-next-line max-len
        domainLimitTip: 'Your organization requires you to use your business email address to access PatSnap. Please login via email.',
        // eslint-disable-next-line max-len
        IPLimitTip: 'Please access the product within the specified IP range.<br>For security reasons, your company has limited access to our products within specified locations e.g.designated offices.',
        dingdingTip: 'Your administrator requires you to login via DingTalk to access PatSnap.',
        feishuTip: 'Your administrator requires you to login via Feishu to access PatSnap.',
        wecomTip: 'Your administrator requires you to login via WeCom to access PatSnap.',
        jwtTip: 'Your administrator requires you to login via Internal Authentication Server to access PatSnap.',
        // eslint-disable-next-line max-len
        ssoTip: 'Your administrator requires you to login via SSO to access PatSnap products.Please access PatSnap through your internal authentication server.',
        switchTenantFailed: 'Failed to switch organization, please contact PatSnap customer service.',
        // eslint-disable-next-line max-len
        accountStatusNotActiveTip: 'Sorry, your email is null or not verified, please go to Account - Personal Information to verify.',
        youWillSwitchedTo: 'You will switch to {tenantName}.',
        error31623: 'Sorry, your can\'t switch to others for data security because your organization use JWT for SSO.',
        error31626: 'Sorry, you can\'t switch to others because your organization forces to login with SSO.',
    },
};
