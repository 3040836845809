import init, { greet_is_good } from '../../wasm/pkg/patsnap_sin.js'
var initWasmPromise;
var initUrl = undefined;

function initWasm() {
    return new Promise<void>((resolve, reject) => {
        const timmer = setTimeout(() => {
            reject(new Error('initWasm timeout'));
        }, 20 * 1000);
        init(initUrl).then(() => {
            clearTimeout(timmer);
            resolve();
        }).catch(reject)
    })
}



export function setWasmFileUrl(url: string) {
    initUrl = url;
}

/**
 * 签名
 * @param url 请求url
 * @param ts 时间戳
 * ! 请注意，还有个隐藏参数，参见rust源码
 * @returns string
 */
export async function sign(url: string, ts: number) {
    if (!initWasmPromise) {
        initWasmPromise = initWasm();
    }
    await initWasmPromise;

    function getPathnameFromUrl(url: string) {
        return (new URL(url)).pathname;
    }

    return greet_is_good(getPathnameFromUrl(url), String(ts));
}