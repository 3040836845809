<template>
    <div
        v-click-outside="hidePopper"
        class="patsnap-biz-header-dropdown"
        :class="{
            [$attrs.align || 'right']: true,
            ['patsnap-biz-header-dropdown--opened']:visible
        }"
    >
        <div
            class="patsnap-biz-header-dropdown__reference"
            @mouseenter="showPopperImmediate"
            @mouseleave="hidePopperSlowly"
            @click="handleClick"
        >
            <slot
                name="reference"
                :open="visible"
                :close="hidePopper"
            />
        </div>
        <div
            v-if="$slots.popper || $scopedSlots.popper"
            v-show="visible"
            ref="popper"
            class="patsnap-biz-header-dropdown__popper"
            @mouseenter="showPopperImmediate"
            @mouseleave="hidePopperSlowly"
        >
            <slot
                name="popper"
                :open="visible"
                :close="hidePopper"
            />
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { mixin as hideTooltipMixin } from './mixins/hide_tooltip_when_popper_show';
/**
 * clickoutside
 */
const ClickOutsideMixin = {
    directives: {
        ClickOutside,
    },
    mounted() {
        this.popupItem = this.$refs.popper;
    },
};

export default {
    name: 'PtHeaderDropdown',
    mixins: [
        ClickOutsideMixin,
        hideTooltipMixin,
    ],
    props: {
        /**
         * 触发 popper 出现的方式。默认为 click，可选值：click ｜ hover
         */
        trigger: {
            type: String,
            default: 'click',
        },
        hideTimeout: {
            type: Number,
            default: 150,
        },
        showTimeout: {
            type: Number,
            default: 250,
        },
        isShowPopoverImmediate: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            visible: false,
        };
    },
    computed: {
        event() {
            return this.trigger === 'hover' ? 'mouseenter' : 'click';
        },
    },
    watch: {
        isShowPopoverImmediate: {
            handler(val) {
                if (val) this.showPopperImmediate();
            },
            immediate: true,
        },
    },
    methods: {
        hidePopper() {
            this.visible = false;
        },
        handleClick() {
            if (this.trigger !== 'hover') {
                this.visible = !this.visible;
                return;
            }
            /**
             * 仅当 trigger: hover 时才会触发。
             * 回传 visible（popper 显示状态） 和 close 方法（用于关闭 popper）
             */
            this.$emit('click', { visible: this.visible, close: this.hidePopper });
        },
        hidePopperSlowly() {
            if (this.trigger !== 'hover') {
                return;
            }
            clearTimeout(this.showIt);
            clearTimeout(this.hideIt);
            this.hideIt = setTimeout(() => {
                this.visible = false;
            }, this.hideTimeout);
        },
        showPopperImmediate() {
            if (this.trigger !== 'hover') return;
            clearTimeout(this.hideIt);
            clearTimeout(this.showIt);
            this.showIt = setTimeout(() => {
                this.visible = true;
            }, this.showTimeout);
        },
    },
};
</script>
