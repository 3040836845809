var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("PtHeaderDropdown", {
    staticClass: "patsnap-biz-languages",
    attrs: { trigger: _vm.$attrs.trigger },
    scopedSlots: _vm._u([
      {
        key: "reference",
        fn: function (ref) {
          var open = ref.open
          return [
            _c("PtHeaderWidget", {
              staticClass: "patsnap-biz-header-widget",
              attrs: {
                icon: "SolidTranslateEnglish",
                label: _vm.$t("PtSelectLanguage.language"),
                "post-icon":
                  _vm.arrow === "down"
                    ? open
                      ? "SolidDropdownClose"
                      : "SolidDropdownOpen"
                    : "SolidDropdownRight",
              },
            }),
          ]
        },
      },
      {
        key: "popper",
        fn: function () {
          return [
            _c("PtList", {
              staticClass: "patsnap-biz-languages__popper",
              attrs: { items: _vm.items },
              on: { itemClick: _vm.handleItemClick },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }