import { Observable } from 'rxjs';
import nextTick from 'next-tick';
import {extractData} from './utils';
import {RequestConfig} from "../types/subs";
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only.js';

function defaultInstantiateObservableFunction(createFn) {
	return new Observable(createFn);
}

export default function psHttpObservableInit (psHttp:(arg0:any) => Promise<any>, instantiateObservableFunction = defaultInstantiateObservableFunction) {

	const flightCtrl = new FlightsCtrlCenter();

	return function psHttp$<R extends any>(config: RequestConfig):Observable<R> {

		function createFn(observer) {
			const controller = new AbortController();

			function cancelReq() {
				controller.abort();
			}

			let requestDone = false;
			flightCtrl.add(config.uuid);

			psHttp(
				Object.assign({}, config, {signal: controller.signal})
			)
				.then(function (o) {
					requestDone = true;
					flightCtrl.remove(config.uuid);
					return o;
				}, (error) => {
					requestDone = true;
					flightCtrl.remove(config.uuid);
					throw error;
				})
				.then(extractData)
				.then(data => {
					observer.next( data );
					observer.complete();
				})
				.catch( ( error ) => {
					observer.error( error );
				} );
			return function () {
				if(config.uuid) {
					if(requestDone) {
						// Do nothing
					} else {
						nextTick(function () {
							if(flightCtrl.hasLastOneActivesFlightsByUuid(config.uuid)){
								cancelReq()
							}
						});
					}
				} else {
					cancelReq();
				}
			};
		}

		return instantiateObservableFunction(createFn) as Observable<R>;
	}
}

class FlightsCtrlCenter{
    actives: {[uuid: string|symbol]: number} = {};
	constructor() {
	}
	changeCountById(uuid, delta) {
		if(!uuid) {
			return;
		}
		if(!this.actives[uuid]) {
			this.actives[uuid] = 0;
		}
		this.actives[uuid] += delta;
		if(this.actives[uuid]<=0){
			delete this.actives[uuid];
		}
		return this.actives[uuid]
	}

	add(uuid) {
		this.changeCountById(uuid, 1);
	}
	remove(uuid) {
		this.changeCountById(uuid, -1);
	}
	hasLastOneActivesFlightsByUuid(uuid){
		return this.actives[uuid] === 1;
	}
}
