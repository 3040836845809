import axios, {AxiosInstance, AxiosPromise, AxiosResponse} from 'axios';
import { csrfTokenInterceptor, migrationWarningInterceptor, cacheInterceptor, validateResponseBodyStatusInterceptor } from './interceptors';
import psHttpObservableInit from './psHttpObservable';
import postByForm, {setPostByFormPassport} from './postByForm';
import {inflightCall,fastReadAuthToken} from './utils';
import {generateExceptionRedirector} from './generateExceptionRedirectHandler';
import RequestChannel from './RequestChannel';
import { cacheAdapterEnhancer, Cache } from '@patsnap-utils/common-axios-extensions';
import type {CommonBody, RequestConfig} from "../types/subs";
import {Observable} from "rxjs";

const {Cancel, CancelToken} = axios;
export { RequestChannel }
export { Cancel, CancelToken }
export { csrfTokenInterceptor,
    fastReadAuthToken, postByForm, setPostByFormPassport };
export { psHttpObservableInit, generateExceptionRedirector }

interface PsResponse<T> extends AxiosResponse<T> {}

export {Cache} // https://www.npmjs.com/package/lru-cache

export {RequestConfig, CommonBody, PsResponse}
export const psAxios = createPsAxiosInstance();


export interface psAxiosInstance extends AxiosInstance {
    psHttp: <T = any>(config:RequestConfig, instance?: psAxiosInstance) => AxiosPromise<T>;
    psHttp$: <R extends any>(config: RequestConfig) => Observable<R>,
    defCache: Cache,
    psInterceptors: {[key:string]: number}
}

const defaultActiveInterceptors = psAxios.psInterceptors;

export {defaultActiveInterceptors}

export function createPsAxiosInstance (options?, customCache?):psAxiosInstance {
    const cache = customCache || new Cache({
        max: 500,
        maxAge: 1000 * 60 * 60 * 30 // 0.5hour
    });
    let inst = axios.create({
        timeout: 62000,
        headers: {
            'x-requested-with': 'XMLHttpRequest'
        },
        adapter: cacheAdapterEnhancer(axios.defaults.adapter, {
            cacheFlag: 'cache',
            defaultCache: cache,
            enabledByDefault: false
        }),
        ...options
    }) as psAxiosInstance;

    let interceptors = {
        csrfTokenInterceptor: inst.interceptors.request.use(csrfTokenInterceptor, error => Promise.reject(error)),
        migrationWarningInterceptor: inst.interceptors.request.use(migrationWarningInterceptor),
        cacheInterceptor: inst.interceptors.request.use(cacheInterceptor),

        validateResponseBodyStatusInterceptor: inst.interceptors.response.use(validateResponseBodyStatusInterceptor)
    };
    inst.psInterceptors = interceptors;

    function psHttp<R extends any>(config:RequestConfig, instance = inst):AxiosPromise<R> {
        let usedConfig = Object.assign({}, config);
        if(usedConfig.uuid) {
            return inflightCall(usedConfig.uuid, usedConfig, instance)
        } else {
            return instance(usedConfig);
        }
    }

    inst.psHttp = psHttp;
    inst.defCache = cache;
    inst.psHttp$ = psHttpObservableInit(psHttp);
    return inst
}

const psHttp = psAxios.psHttp;
export default psHttp;



export const psHttp$ = psAxios.psHttp$;
