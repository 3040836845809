
const active = {}
inflight.active = active
function inflight (unique, doFly) {
	return Promise.all([Promise.resolve(unique), doFly]).then(function (args) {
		const unique = args[0]
		const doFly = args[1]
		if (Array.isArray(unique)) {
			return Promise.all(unique).then(function (uniqueArr) {
				return _inflight(uniqueArr.join(''), doFly)
			})
		} else {
			return _inflight(unique, doFly)
		}
	})

	function _inflight (unique, doFly) {
        function cleanup() { delete active[unique] }
		if (!active[unique]) {
			active[unique] = (new Promise(function (resolve) {
				return resolve(doFly())
			}))
			active[unique].then(cleanup, cleanup)
		}
		return active[unique]
	}
}

export default inflight
