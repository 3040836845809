var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "ul",
        { staticClass: "patsnap-biz-user-banner" },
        [
          _vm._t("username", function () {
            return [
              _vm.account.username
                ? _c("li", { staticClass: "patsnap-biz-user-banner__item" }, [
                    _vm._v(" " + _vm._s(_vm.account.username) + " "),
                  ])
                : _vm._e(),
            ]
          }),
          _vm.account.email
            ? _c("li", { staticClass: "patsnap-biz-user-banner__item" }, [
                _vm._v(" " + _vm._s(_vm.account.email) + " "),
              ])
            : _vm._e(),
          _vm.account.mobileNumber
            ? _c("li", { staticClass: "patsnap-biz-user-banner__item" }, [
                _vm._v(" " + _vm._s(_vm.account.mobileNumber) + " "),
              ])
            : _vm._e(),
          _vm._t("userPackageType"),
          _c("li", { staticClass: "patsnap-biz-user-banner__divider" }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }