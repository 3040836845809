export default {
    PtHeader: {
        help: '幫助', // ヘルプ
        helpCenter: '幫助中心', // ヘルプセンター
        quickStartGuide: '新手入門', // 初心者向けガイド
        gettingStartedVideos: '入門視頻', // 入門ビデオ
        searchHelper: '搜索幫助', // 検索ヘルプ
        releaseLog: '最新功能', // リリース履歴
        dataCoverage: '數據範圍', // データ収録範囲
        askForHelp: '需要幫助', // お問合せ
        products: '産品',
        workspace: '工作空間',
        marketPlace: '導航庫中心',
        analytics: '專利數據庫',
        insights: '英策',
        bio: 'Bio',
        chemical: 'Chemical',
        discovery: 'Discovery',
        synapse: '新藥情報庫',
        academy: '智慧芽學社',
        course: '智慧芽學社',
        course2b: '企業版學社',
        lab: 'PatSnap Lab',
        open: '數據服務平台',
        ipAsset: '資産管理',
        allProducts: '産品',
        tffi: 'TFFI',
        eureka: 'Eureka',
        pharmsnap: 'PharmSnap',
    },
};
