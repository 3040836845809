const ATTR_NAME = 'data-balloon-pos';
const SELECTOR_TOOLTIP = '[data-balloon-pos]';

function TooltipUtil(el) {
    const widget = el.querySelector(SELECTOR_TOOLTIP);
    let pos;
    if (widget) {
        pos = widget.getAttribute(ATTR_NAME);
    }

    return {
        hide() {
            if (widget) widget.removeAttribute(ATTR_NAME);
        },
        show() {
            if (widget) widget.setAttribute(ATTR_NAME, pos);
        },
        visible(visible) {
            if (visible) {
                this.show();
            } else {
                this.hide();
            }
        },
    };
}

export const mixin = {
    watch: {
        visible(visible) {
            if (this.tooltipUtil) {
                this.tooltipUtil.visible(!visible);
            }
        },
    },
    mounted() {
        if (this.$el) {
            this.tooltipUtil = TooltipUtil(this.$el);
        }
    },

    beforeDestroy() {
        if (this.tooltipUtil) {
            this.tooltipUtil = undefined;
        }
    },
};
