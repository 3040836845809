import get from 'lodash/get';
import { sleep } from './utils';

const redirectingError = new Error('Please login, redirecting...');

function redirectingHolder (error) {
	return sleep(2500).then(() => Promise.reject(error || redirectingError));
}

function defaultHandler(error, code) {
	let redirectUrl = get(error, 'response.data.redirectUrl');
	if(redirectUrl){
        window.location.href = redirectUrl;
        return redirectingHolder(error)
	}
    return Promise.reject(error);
}


export function generateExceptionRedirector (codeOrCodes, callbackOrLocation:Function|string = defaultHandler) {
	return function exceptionPreHandler<E>(error: Error): Promise<E> {
		let codes = Array.isArray(codeOrCodes) ? codeOrCodes : [codeOrCodes];
		let statusCode = get(error, 'response.status');
		if(codes.indexOf(statusCode) >= 0){
			if(typeof callbackOrLocation === 'function') {
				return callbackOrLocation(error, statusCode)
			}else if(typeof callbackOrLocation === 'string'){
				window.location.href = callbackOrLocation || '/';
				return redirectingHolder(error)
			}
		}
		return Promise.reject(error);
	}
}
