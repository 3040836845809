var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "patsnap-biz-list" }, [
    _vm.$slots.prefix
      ? _c(
          "div",
          { staticClass: "patsnap-biz-list-prefix" },
          [_vm._t("prefix")],
          2
        )
      : _vm._e(),
    _c(
      "ul",
      { staticClass: "patsnap-ui-list" },
      [
        _vm._l(_vm.normalizeItems, function (item, index) {
          var _obj
          return [
            item && item.divider
              ? _c(
                  "li",
                  {
                    key: "pt_list_" + index,
                    class: {
                      "patsnap-ui-list-divider": true,
                      "patsnap-ui-list-divider--with-icon": item.icon,
                      "patsnap-ui-list-divider--with-label": item.label,
                    },
                  },
                  [
                    _vm._t(
                      "divider",
                      function () {
                        return [
                          item.icon
                            ? _c("PtIcon", {
                                staticClass: "patsnap-ui-list-divider__icon",
                                attrs: { icon: item.icon },
                              })
                            : _vm._e(),
                          item.label
                            ? _c(
                                "span",
                                {
                                  staticClass: "patsnap-ui-list-divider__text",
                                },
                                [_vm._v(_vm._s(item.label))]
                              )
                            : _vm._e(),
                          _c("hr"),
                        ]
                      },
                      { item: item }
                    ),
                  ],
                  2
                )
              : _c(
                  "li",
                  _vm._g(
                    {
                      key: "pt_list_" + index,
                      class: Object.assign(
                        {},
                        { active: item.active, "patsnap-ui-list-item": true },
                        item.classname
                          ? ((_obj = {}), (_obj[item.classname] = true), _obj)
                          : {}
                      ),
                      on: {
                        click: function ($event) {
                          return _vm.handleItemClick(item)
                        },
                      },
                    },
                    item.$listeners || {}
                  ),
                  [
                    _vm.getSlotName(item.label)
                      ? _vm._t(_vm.getSlotName(item.label))
                      : _vm._t(
                          "default",
                          function () {
                            return [
                              item.icon
                                ? _c("PtIcon", {
                                    staticClass:
                                      "patsnap-ui-list-item__pre-icon",
                                    attrs: { icon: item.icon },
                                  })
                                : _vm._e(),
                              item.href
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "patsnap-ui-list-item__content",
                                      attrs: {
                                        href: item.href,
                                        target: _vm.calcTargetByItem(item),
                                      },
                                    },
                                    [_c("span", [_vm._v(_vm._s(item.label))])]
                                  )
                                : _c(
                                    "span",
                                    {
                                      staticClass:
                                        "patsnap-ui-list-item__content",
                                    },
                                    [_vm._v(_vm._s(item.label))]
                                  ),
                              item.postIcon
                                ? _c("PtIcon", {
                                    staticClass:
                                      "patsnap-ui-list-item__post-icon",
                                    attrs: { icon: item.postIcon },
                                  })
                                : _vm._e(),
                            ]
                          },
                          { item: item }
                        ),
                  ],
                  2
                ),
          ]
        }),
      ],
      2
    ),
    _vm.$slots.post
      ? _c("div", { staticClass: "patsnap-biz-list-post" }, [_vm._t("post")], 2)
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }