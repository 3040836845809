var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "patsnap-biz-user_center" },
    [
      !_vm.service.logged
        ? _c(
            "div",
            {
              staticClass: "patsnap-biz-user_center--not-logged-in",
              attrs: { flex: "-center" },
            },
            [
              _vm._t("language"),
              _c(
                "a",
                {
                  staticClass: "pt-ui-btn patsnap-biz-user_center__btn",
                  attrs: {
                    type: "primary",
                    _target: "_self",
                    href: _vm.service.logoutHref,
                  },
                  on: { click: _vm.handleLoginClick },
                },
                [_vm._v(" " + _vm._s(_vm.$t("PtUserCenter.login")) + " ")]
              ),
            ],
            2
          )
        : _c("PtHeaderDropdown", {
            staticClass: "patsnap-biz-user_center--logged",
            attrs: { trigger: "hover" },
            scopedSlots: _vm._u(
              [
                {
                  key: "reference",
                  fn: function () {
                    return [
                      _c("PtAvatar", {
                        directives: [
                          {
                            name: "click-outside",
                            rawName: "v-click-outside",
                            value: _vm.handleClickOutsideAvatar,
                            expression: "handleClickOutsideAvatar",
                          },
                        ],
                        attrs: {
                          username: _vm.service.name,
                          avatar: _vm.service.avatar,
                          "avatar-color": _vm.service.avatarColor,
                        },
                        on: { click: _vm.handleClickAvatar },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "popper",
                  fn: function () {
                    return [
                      _vm._t("header", null, { trigger: "hover" }),
                      _c("PtList", {
                        ref: "popper",
                        staticClass: "patsnap-biz-user_center_menu",
                        attrs: { items: _vm.items },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "language",
                              fn: function () {
                                return [
                                  _vm._t("language", null, {
                                    trigger: "hover",
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            _vm._l(_vm.tranformSlotNames, function (name) {
                              return {
                                key: name,
                                fn: function () {
                                  return [_vm._t(name)]
                                },
                                proxy: true,
                              }
                            }),
                          ],
                          null,
                          true
                        ),
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }