/* eslint-disable max-len */
import { Site, SLD } from '@patsnap-utils/biz';

const helpCenterHref = new Site({ sld: SLD.HELP }).href;

// search cn/en-us配置
const AdvanceSimpleZhihuiyaLinkConfig = [{
    label: '简单搜索',
    href: `${helpCenterHref}/help/360008351411/article/360041371671?categoryId=360002609831`,
}, {
    label: '检索逻辑',
    href: `${helpCenterHref}/help/360008250552/article/900000910826?categoryId=360002609831`,
}];

const AdvanceSimplePatsnapLinkConfig = [{
    label: 'Searching in PatSnap 101',
    href: `${helpCenterHref}/hc/en-us/articles/212268765`,
}, {
    label: 'Stemming and Wildcards 101',
    href: `${helpCenterHref}/hc/en-us/articles/207448135`,
}];

const AdvanceFieldZhihuiyaLinkConfig = [{
    label: '高级搜索（字段搜索方式）',
    href: `${helpCenterHref}/help/360008351411/article/360041372271?categoryId=360002609831`,
}, {
    label: '高级搜索（编辑检索式方式）',
    href: `${helpCenterHref}/help/360008351411/article/360041374911?categoryId=360002609831`,
}, {
    label: '高级搜索（编辑组合检索式方式）',
    href: `${helpCenterHref}/help/360008351411/article/360041379451?categoryId=360002609831`,
}, {
    label: '检索逻辑',
    href: `${helpCenterHref}/help/360008250552/article/900000910826?categoryId=360002609831`,
}];

const AdvanceFieldPatsnapLinkConfig = [{
    label: 'Field Search 101',
    href: `${helpCenterHref}/hc/en-us/articles/115005456725`,
}, {
    label: 'Stemming and Wildcards 101',
    href: `${helpCenterHref}/hc/en-us/articles/207448135`,
}];

const AdvanceBulkZhihuiyaLinkConfig = [{
    label: '批量搜索',
    href: `${helpCenterHref}/help/360008351411/article/360041446651?categoryId=360002609831`,
}];

const AdvanceBulkPatsnapLinkConfig = [{
    label: 'Bulk Search',
    href: `${helpCenterHref}/hc/en-us/articles/208465415`,
}];

const AdvanceSemanticZhihuiyaLinkConfig = [{
    label: '语义搜索',
    href: `${helpCenterHref}/help/360008351411/article/360041011292?categoryId=360002609831`,
}];

const AdvanceSemanticPatsnapLinkConfig = [{
    label: 'What Is Semantic Search? How Does It Work?',
    href: `${helpCenterHref}/hc/en-us/articles/210698769`,
}];

const AdvanceImageZhihuiyaLinkConfig = [{
    label: '图像搜索',
    href: `${helpCenterHref}/help/360008351411/article/360041434391?categoryId=360002609831`,
}];

const AdvanceImagePatsnapLinkConfig = [{
    label: 'What is Image Search? How Can I Search for Design Patents Within PatSnap?',
    href: `${helpCenterHref}/hc/en-us/articles/115000937409`,
}];

const searchZhihuiyaMoreLink = {
    label: '更多关于搜索的帮助信息',
    href: `${helpCenterHref}/help/section/360008351411?categoryId=360002609831`,
};

const searchPatsnapMoreLink = {
    label: 'More help information about patent search',
    href: `${helpCenterHref}/hc/en-us/sections/8496388768413`,
};

// next cn/en-us配置
const nextZhihuiyaLinkConfig = [{
    label: '筛选检索结果',
    href: `${helpCenterHref}/help/360008382811/article/900000961443`,
}, {
    label: '检索结果列表的处理',
    href: `${helpCenterHref}/help/360008382811/article/900000961403`,
}, {
    label: '检索逻辑',
    href: `${helpCenterHref}/help/360008250552/article/900000910826`,
}];

const nextPatsnapLinkConfig = [{
    label: 'Grouping Your Results',
    href: `${helpCenterHref}/hc/en-us/articles/209201549`,
}, {
    label: 'Filtering Your Results',
    href: `${helpCenterHref}/hc/en-us/articles/115005527049`,
}, {
    label: 'Search Settings and Search Result Display',
    href: `${helpCenterHref}/hc/en-us/articles/115002134285`,
}, {
    label: 'Stemming and Wildcards 101',
    href: `${helpCenterHref}/hc/en-us/articles/207448135`,
}];

const nextZhihuiyaMoreLink = {
    label: '更多关于搜索的帮助信息',
    href: `${helpCenterHref}/help/section/360008351411`,
};

const nextPatsnapMoreLink = {
    label: 'More help information about patent search',
    href: `${helpCenterHref}/hc/en-us/sections/8496388768413`,
};

// patent view cn/en-us配置
const patentViewZhihuiyaLinkConfig = [{
    label: '查看专利详情信息',
    href: `${helpCenterHref}/help/360008382811/article/360041063912`,
}, {
    label: '查看专利的法律信息',
    href: `${helpCenterHref}/help/360008382811/article/360041067112`,
}, {
    label: '查看专利的引用信息',
    href: `${helpCenterHref}/help/360008382811/article/360041495891`,
}, {
    label: '查看专利的同族专利',
    href: `${helpCenterHref}/help/360008382811/article/360041498751`,
}, {
    label: '查看专利的相似专利',
    href: `${helpCenterHref}/help/360008382811/article/900003240026`,
}];

const patentViewPatsnapLinkConfig = [{
    label: 'What Can I Do When I View A Patent?',
    href: `${helpCenterHref}/hc/en-us/articles/115005478629`,
}, {
    label: 'How Can I Compare Two Patents With Each Other?',
    href: `${helpCenterHref}/hc/en-us/articles/4412009294865`,
}, {
    label: 'Claim and Description Comparison - How to use it?',
    href: `${helpCenterHref}/hc/en-us/articles/360012103898`,
}, {
    label: 'What Are The Different Legal Options When Viewing A Patent?',
    href: `${helpCenterHref}/hc/en-us/articles/115005475405`,
}, {
    label: 'Where Do I Find The Priority Information For A Patent?',
    href: `${helpCenterHref}/hc/en-us/articles/360000335105`,
}];

const patentViewZhihuiyaMoreLink = {
    label: '更多关于专利详情的帮助信息',
    href: `${helpCenterHref}/help/section/360008382811`,
};

const patentViewPatsnapMoreLink = {
    label: 'More help information about patent view',
    href: `${helpCenterHref}/hc/en-us/sections/8500434418845`,
};

// workspace cn/en-us配置
const workspaceZhihuiyaLinkConfig = [{
    label: '关于智慧芽工作空间',
    href: `${helpCenterHref}/help/15430887249561/article/15432831301657?categoryId=15429297885337`,
}, {
    label: '如何搜索工作空间数据？',
    href: `${helpCenterHref}/help/15430887249561/article/31564258906009?categoryId=15429297885337`,
}, {
    label: '工作空间的类型',
    href: `${helpCenterHref}/help/15430887249561/article/15432789536281?categoryId=15429297885337`,
}];

const workspacePatsnapLinkConfig = [{
    label: 'About Workspaces',
    href: `${helpCenterHref}/hc/en-us/articles/115005950645`,
}, {
    label: 'How to search within Workspaces?',
    href: `${helpCenterHref}/hc/en-us/articles/360013984778`,
}, {
    label: 'Workspace types',
    href: `${helpCenterHref}/hc/en-us/articles/360018589298`,
}];

export const workspaceDetailZhihuiyaConfig = [{
    label: '如何开启文件夹数据的自动更新？',
    href: `${helpCenterHref}/help/15430887249561/article/31564303462169?categoryId=15429297885337`,
}, {
    label: '如何使用 AI 标引？',
    href: `${helpCenterHref}/help/15430887249561/article/25312512061209?categoryId=15429297885337`,
}, {
    label: '如何分享工作空间和文件夹？',
    href: `${helpCenterHref}/help/15430887249561/article/15433073336857?categoryId=15429297885337`,
}, {
    label: '怎样创建自定义字段？',
    href: `${helpCenterHref}/help/15430887249561/article/15432990304409?categoryId=15429297885337`,
}, {
    label: '如何批量对专利进行标引？',
    href: `${helpCenterHref}/help/15430887249561/article/15433014022553?categoryId=15429297885337`,
}, {
    label: '如何监控工作空间的专利动态？',
    href: `${helpCenterHref}/help/15430887249561/article/31564344797849?categoryId=15429297885337`,
}];

export const workspaceDetailPatsnapConfig = [{
    label: 'How do I make a Workspace folder that automatically updates?',
    href: `${helpCenterHref}/hc/en-us/articles/211548209`,
}, {
    label: 'What is AI tagging and how do I use it?',
    href: `${helpCenterHref}/hc/en-us/articles/4416660932241`,
}, {
    label: 'How do I share or save a Workspace?',
    href: `${helpCenterHref}/hc/en-us/articles/207743219`,
}, {
    label: 'What are custom fields?',
    href: `${helpCenterHref}/hc/en-us/articles/207745039`,
}, {
    label: 'How do I batch tag patent records with custom fields?',
    href: `${helpCenterHref}/hc/en-us/articles/360005357517`,
}, {
    label: 'How do I use email alerts to monitor competitor activity?',
    href: `${helpCenterHref}/hc/en-us/articles/115005322065`,
}];

const workspaceZhihuiyaMoreLink = {
    label: '更多关于工作空间的帮助信息',
    href: `${helpCenterHref}/help/section/15430887249561?categoryId=15429297885337`,
};

const workspacePatsnapMoreLink = {
    label: 'More help information about workspace',
    href: `${helpCenterHref}/hc/en-us/sections/8500410715933`,
};

// analysis cn/en-us配置
const analysisZhihuiyaLinkConfig = [{
    label: '高级分析',
    href: `${helpCenterHref}/help/360008281872/article/360041119172`,
}, {
    label: '高级分析（自定义分析）',
    href: `${helpCenterHref}/help/360008281872/article/360041556151`,
}];

const analysisPatsnapLinkConfig = [{
    label: 'Results Analysis 101',
    href: `${helpCenterHref}/hc/en-us/articles/360000706657`,
}, {
    label: 'What Information is Visible in the Analyze Overview?',
    href: `${helpCenterHref}/hc/en-us/articles/115005392349`,
}, {
    label: 'Exporting Analyses (From Analyze Patent Tab)',
    href: `${helpCenterHref}/hc/en-us/articles/208599889`,
}, {
    label: 'How Does Trend Prediction In Analytics Work?',
    href: `${helpCenterHref}/hc/en-us/articles/115005431965`,
}];

// landscape cn/en-us配置
const landscapeZhihuiyaLinkConfig = [{
    label: '3D专利地图分析',
    href: `${helpCenterHref}/help/360008281872/article/360041556411`,
}];

const landscapePatsnapLinkConfig = [{
    label: 'How can Landscape be used for different tasks?',
    href: `${helpCenterHref}/hc/en-us/articles/115000409785`,
}, {
    label: 'What Are The Different Options Available On Landscape?',
    href: `${helpCenterHref}/hc/en-us/articles/115005559205`,
}, {
    label: 'How Is The Landscape Made?',
    href: `${helpCenterHref}/hc/en-us/articles/207732719`,
}, {
    label: 'How Can I Upload A Patent List And Generate A Landscape?',
    href: `${helpCenterHref}/hc/en-us/articles/360000164098`,
}];

const landscapePatsnapMoreLink = {
    label: 'More help information about landscape',
    href: `${helpCenterHref}/hc/en-us/sections/8500430418333`,
};

const guiderModuleLinkMap = {
    search: {
        AdvanceSimple: {
            cn: AdvanceSimpleZhihuiyaLinkConfig,
            us: AdvanceSimplePatsnapLinkConfig,
            eu: AdvanceSimplePatsnapLinkConfig,
        },
        AdvanceField: {
            cn: AdvanceFieldZhihuiyaLinkConfig,
            us: AdvanceFieldPatsnapLinkConfig,
            eu: AdvanceFieldPatsnapLinkConfig,
        },
        AdvanceBulk: {
            cn: AdvanceBulkZhihuiyaLinkConfig,
            us: AdvanceBulkPatsnapLinkConfig,
            eu: AdvanceBulkPatsnapLinkConfig,
        },
        AdvanceSemantic: {
            cn: AdvanceSemanticZhihuiyaLinkConfig,
            us: AdvanceSemanticPatsnapLinkConfig,
            eu: AdvanceSemanticPatsnapLinkConfig,
        },
        AdvanceImage: {
            cn: AdvanceImageZhihuiyaLinkConfig,
            us: AdvanceImagePatsnapLinkConfig,
            eu: AdvanceImagePatsnapLinkConfig,
        },
    },
    next: {
        cn: nextZhihuiyaLinkConfig,
        us: nextPatsnapLinkConfig,
        eu: nextPatsnapLinkConfig,
    },
    patentView: {
        cn: patentViewZhihuiyaLinkConfig,
        us: patentViewPatsnapLinkConfig,
        eu: patentViewPatsnapLinkConfig,
    },
    workspace: {
        cn: workspaceZhihuiyaLinkConfig,
        us: workspacePatsnapLinkConfig,
        eu: workspacePatsnapLinkConfig,
        detail: {
            cn: workspaceDetailZhihuiyaConfig,
            us: workspaceDetailPatsnapConfig,
            eu: workspaceDetailPatsnapConfig,
        },
    },
    analysis: {
        cn: analysisZhihuiyaLinkConfig,
        us: analysisPatsnapLinkConfig,
        eu: analysisPatsnapLinkConfig,
    },
    landscape: {
        cn: landscapeZhihuiyaLinkConfig,
        us: landscapePatsnapLinkConfig,
        eu: landscapePatsnapLinkConfig,
    },
};

const guiderModuleMoreMap = {
    search: {
        cn: searchZhihuiyaMoreLink,
        us: searchPatsnapMoreLink,
        eu: searchPatsnapMoreLink,
    },
    next: {
        cn: nextZhihuiyaMoreLink,
        us: nextPatsnapMoreLink,
        eu: nextPatsnapMoreLink,
    },
    patentView: {
        cn: patentViewZhihuiyaMoreLink,
        us: patentViewPatsnapMoreLink,
        eu: patentViewPatsnapMoreLink,
    },
    workspace: {
        cn: workspaceZhihuiyaMoreLink,
        us: workspacePatsnapMoreLink,
        eu: workspacePatsnapMoreLink,
    },
    landscape: {
        us: landscapePatsnapMoreLink,
        eu: landscapePatsnapMoreLink,
    },
};

export default {
    computed: {
        guiderData() {
            if (!this.guiderConfig || !this.guiderConfig.module) return {};
            const linkList = this.guiderConfig.subModule
                ? guiderModuleLinkMap[this.guiderConfig.module]?.[this.guiderConfig.subModule]?.[this.guiderConfig.serviceArea]
                : guiderModuleLinkMap[this.guiderConfig.module]?.[this.guiderConfig.serviceArea];
            const linkMore = guiderModuleMoreMap[this.guiderConfig.module]?.[this.guiderConfig.serviceArea];
            return linkList && linkList.length > 0 ? { linkList, linkMore } : {};
        },
    },
};
