export default {
    PtHeader: {
        help: 'Help', // ヘルプ
        helpCenter: 'Help Center', // ヘルプセンター
        quickStartGuide: 'Quick Start Guide', // 初心者向けガイド
        gettingStartedVideos: 'Getting Started Videos', // 入門ビデオ
        searchHelper: 'Search Helper', // 検索ヘルプ
        releaseLog: 'Release Log', // リリース履歴
        dataCoverage: 'Data Coverage', // データ収録範囲
        askForHelp: 'Ask For Help', // お問合せ
        products: 'Products',
        workspace: 'Workspace',
        marketPlace: 'Marketplace',
        analytics: 'Analytics',
        insights: 'Insights',
        bio: 'Bio',
        chemical: 'Chemical',
        discovery: 'Discovery',
        synapse: 'Synapse',
        academy: 'Academy',
        course: 'Academy',
        course2b: 'Course',
        lab: 'PatSnap Lab',
        open: 'Data Service',
        ipAsset: 'IP Asset',
        allProducts: 'Products',
        tffi: 'TFFI',
        eureka: 'Eureka',
        pharmsnap: 'PharmSnap',
    },
};
