var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "patsnap-biz-header",
      class:
        ((_obj = {}),
        (_obj["patsnap-biz-header--" + _vm.service.type] = true),
        _obj),
    },
    [
      _vm.service.whiteLabel && _vm.service.customLogoVisible
        ? _c(
            "a",
            {
              staticClass:
                "patsnap-biz-header__logo patsnap-biz-header__custom-logo",
              attrs: { href: _vm.service.logoHref },
            },
            [
              _vm.service.type === "primary"
                ? _c("img", { attrs: { src: _vm.service.customWhiteLogoUrl } })
                : _c("img", { attrs: { src: _vm.service.customLogoUrl } }),
            ]
          )
        : _vm._e(),
      _vm.logoVisible && _vm.service.whiteLabel && _vm.service.customLogoVisible
        ? _c("div", { staticClass: "patsnap-biz-header__divider" })
        : _vm._e(),
      _vm.logoVisible
        ? _c(
            "a",
            {
              staticClass: "patsnap-biz-header__logo",
              attrs: { href: _vm.service.logoHref },
            },
            [_vm._t("logo", null, { service: _vm.service })],
            2
          )
        : _vm._e(),
      _vm.service.title &&
      (_vm.logoVisible ||
        (_vm.service.whiteLabel && _vm.service.customLogoVisible))
        ? _c("div", { staticClass: "patsnap-biz-header__divider" })
        : _vm._e(),
      _vm.service.title
        ? _c(
            "div",
            { staticClass: "patsnap-biz-header__title" },
            [
              _vm.getSlotName(_vm.service.title)
                ? _vm._t(_vm.getSlotName(_vm.service.title))
                : _c("span", [_vm._v(" " + _vm._s(_vm.service.title) + " ")]),
            ],
            2
          )
        : _vm._e(),
      _c(
        "ul",
        {
          staticClass: "patsnap-biz-header__widgets left",
          style: {
            marginLeft: _vm.service.checkLogoOrTitleVisible(
              _vm.$scopedSlots.logo
            )
              ? "32px"
              : "0",
          },
        },
        [
          _vm._l(_vm.service.left, function (item, index) {
            return [
              _vm.getSlotName(item)
                ? _c(
                    "div",
                    {
                      key: "pt-header-left-widget-" + index,
                      staticClass: "patsnap-biz-header__widget-item",
                    },
                    [_vm._t(_vm.getSlotName(item))],
                    2
                  )
                : !item.menu
                ? _c(
                    "div",
                    {
                      key: "pt-header-left-widget-" + index,
                      staticClass: "patsnap-biz-header__widget-item",
                    },
                    [
                      _c(
                        "PtHeaderWidget",
                        _vm._b(
                          {
                            attrs: {
                              href: item.href,
                              icon: item.icon,
                              label: item.label,
                              "post-icon": item.postIcon,
                              "aria-label": item.tooltip,
                              "data-balloon-pos": "down",
                            },
                          },
                          "PtHeaderWidget",
                          {
                            target: item.href
                              ? item.target || "_blank"
                              : undefined,
                          },
                          false
                        )
                      ),
                    ],
                    1
                  )
                : _c("PtHeaderDropdown", {
                    key: "pt-header-left-widget-" + index,
                    staticClass: "patsnap-biz-header__widget-item",
                    attrs: {
                      trigger: item.trigger === "hover" ? "hover" : "click",
                      align: "left",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "reference",
                          fn: function () {
                            return [
                              _c("PtHeaderWidget", {
                                attrs: {
                                  icon: item.icon,
                                  label: item.label,
                                  "post-icon": item.postIcon,
                                  "aria-label": item.tooltip,
                                  "data-balloon-pos": "down",
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "popper",
                          fn: function () {
                            var _obj
                            return [
                              _vm.getSlotName(item.menu)
                                ? _vm._t(_vm.getSlotName(item.menu))
                                : _c("PtList", {
                                    class:
                                      ((_obj = {}),
                                      (_obj[item.classname || ""] = true),
                                      _obj),
                                    attrs: { items: item.menu },
                                  }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      true
                    ),
                  }),
            ]
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "patsnap-biz-header__spacer" },
        [_vm._t("default")],
        2
      ),
      _vm.service.syncing
        ? _c(
            "ul",
            {
              staticClass:
                "patsnap-biz-header__widgets patsnap-biz-header__widgets-skeleton",
            },
            [_c("li"), _c("li"), _c("li")]
          )
        : _c(
            "ul",
            { staticClass: "patsnap-biz-header__widgets right" },
            [
              _vm._l(_vm.service.right, function (item, index) {
                return [
                  _vm.getSlotName(item)
                    ? _c(
                        "div",
                        {
                          key: "pt-header-right-widget-" + index,
                          staticClass: "patsnap-biz-header__widget-item",
                        },
                        [_vm._t(_vm.getSlotName(item))],
                        2
                      )
                    : !item.menu
                    ? _c(
                        "div",
                        {
                          key: "pt-header-right-widget-" + index,
                          staticClass: "patsnap-biz-header__widget-item",
                        },
                        [
                          _c(
                            "PtHeaderWidget",
                            _vm._b(
                              {
                                attrs: {
                                  href: item.href,
                                  icon: item.icon,
                                  label: item.label,
                                  "post-icon": item.postIcon,
                                  "aria-label": item.tooltip,
                                  "data-balloon-pos": "down",
                                },
                              },
                              "PtHeaderWidget",
                              {
                                target: item.href
                                  ? item.target || "_blank"
                                  : undefined,
                              },
                              false
                            )
                          ),
                        ],
                        1
                      )
                    : _c("PtHeaderDropdown", {
                        key: "pt-header-right-widget-" + index,
                        staticClass: "patsnap-biz-header__widget-item",
                        attrs: {
                          trigger: item.trigger === "hover" ? "hover" : "click",
                          "is-show-popover-immediate": item.showPopper,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "reference",
                              fn: function () {
                                return [
                                  _c("PtHeaderWidget", {
                                    attrs: {
                                      icon: item.icon,
                                      label: item.label,
                                      "post-icon": item.postIcon,
                                      "aria-label": item.tooltip,
                                      "data-balloon-pos": "down",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "popper",
                              fn: function () {
                                var _obj
                                return [
                                  _vm.getSlotName(item.menu)
                                    ? _vm._t(_vm.getSlotName(item.menu))
                                    : _c("PtList", {
                                        class:
                                          ((_obj = {}),
                                          (_obj[item.classname || ""] = true),
                                          (_obj["column-layout"] =
                                            _vm.service
                                              .helpCenterGuiderVisible &&
                                            _vm.service
                                              .helpCenterGuiderConfig &&
                                            item.hasGuider),
                                          _obj),
                                        attrs: { items: item.menu },
                                        scopedSlots: _vm._u(
                                          [
                                            _vm.service
                                              .helpCenterGuiderVisible &&
                                            _vm.service
                                              .helpCenterGuiderConfig &&
                                            item.hasGuider
                                              ? {
                                                  key: "prefix",
                                                  fn: function () {
                                                    return [
                                                      _c("HelpCenterGuider", {
                                                        attrs: {
                                                          "guider-config":
                                                            _vm.service
                                                              .helpCenterGuiderConfig,
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                }
                                              : null,
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                ]
              }),
              _vm.service.canUseNotifications
                ? _c(
                    "div",
                    { staticClass: "patsnap-biz-header__widget-item" },
                    [_c("Notifications")],
                    1
                  )
                : _vm._e(),
              _vm.service.visibleProducts
                ? _c("PtHeaderDropdown", {
                    staticClass: "patsnap-biz-header__widget-item",
                    attrs: { trigger: "hover" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "reference",
                          fn: function () {
                            return [
                              _c("PtHeaderWidget", {
                                attrs: { icon: "Products" },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "popper",
                          fn: function () {
                            return [
                              _c("PtProductsShell", {
                                ref: "products",
                                attrs: {
                                  "home-btn-visible": !!(
                                    _vm.service.account &&
                                    _vm.service.account.canAccessHomePage
                                  ),
                                },
                              }),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      72236241
                    ),
                  })
                : _vm._e(),
              _c("PtUserCenter", {
                ref: "userCenter",
                staticClass: "patsnap-biz-header__widget-item",
                attrs: { service: _vm.service.userCenterService },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "header",
                      fn: function (ref) {
                        var trigger = ref.trigger
                        return [
                          _c("Tenants", {
                            attrs: {
                              value: _vm.service.tenants,
                              trigger: trigger,
                            },
                          }),
                          _c("UserBanner", {
                            attrs: { account: _vm.service.account },
                            scopedSlots: _vm._u(
                              [
                                _vm._l(_vm.tranformSlotNames, function (name) {
                                  return {
                                    key: name,
                                    fn: function () {
                                      return [_vm._t(name)]
                                    },
                                    proxy: true,
                                  }
                                }),
                              ],
                              null,
                              true
                            ),
                          }),
                        ]
                      },
                    },
                    {
                      key: "language",
                      fn: function (ref) {
                        var trigger = ref.trigger
                        return [
                          _c("Languages", {
                            ref: "language",
                            attrs: {
                              value: _vm.service.locales,
                              trigger: trigger,
                              arrow: trigger ? "right" : "down",
                            },
                            on: { change: _vm.handleLanguageChange },
                          }),
                        ]
                      },
                    },
                    _vm._l(_vm.$slots, function (value, name) {
                      return {
                        key: name,
                        fn: function () {
                          return [
                            name !== "header" && name !== "language"
                              ? _vm._t(name)
                              : _vm._e(),
                          ]
                        },
                        proxy: true,
                      }
                    }),
                  ],
                  null,
                  true
                ),
              }),
            ],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }