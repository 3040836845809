export default {
    PtUserCenter: {
        login: '登录',
        logout: '登出',
        account: '用户设置',
        adminSettings: '管理员工作台',
        team: '团队',
        projectTimer: '项目计时',
        personal: '个人',
        enterprise: '企业',
    },
};
