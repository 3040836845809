import get from 'lodash/get';
import { RequestConfig } from '../types/subs';
const isIE = (function () {
	try{
		const ua = navigator ? navigator.userAgent : '';
		return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
	} catch(err) {
		return false
	}
})();
const currentTime = new Date().getTime();

export function csrfTokenInterceptor(config) {
	// 不再需要csrfToken
	// if (config && String(config.method).toLowerCase() === 'post') {
	// 	return prepareToken(true, true)
	// 		.then(tkHeader => {
	// 			config.headers = Object.assign(config.headers || {}, tkHeader || {});
	// 			return config;
	// 		});
	// }
	return config;
};

export function cacheInterceptor(config: RequestConfig) {
	return config;
};


export const migrationWarningInterceptor = config => {
	if (config && String(config.method).toLowerCase() === 'get' && config.data) {
		if(console && console.warn){
			console.warn(`ps-http WARNING: GET requests should use 'params' instead of 'data' as request payload: ${config.url}`)
		}
	}
	return config;
};

export function validateResponseBodyStatusInterceptor(response) {
	if (response.config.validateBodyStatus) {
		if (get(response, 'data.status')) {
			return response;
		}
		return Promise.reject(response);
	}else{
		return response
	}
}
