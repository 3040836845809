export default {
    PtUserCenter: {
        login: 'ログイン',
        logout: 'ログアウト',
        account: 'アカウント',
        adminSettings: '管理設定',
        team: 'チーム',
        projectTimer: 'タイマー',
        personal: '個人',
        enterprise: '企業',
    },
};
