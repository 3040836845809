import { fastReadAuthToken } from './utils';
import { addMultipleParams } from 'url-query-handle';

let _passport = undefined;

export default function postByForm (newWindowOrFormElement, path, params) {
	// The rest of this code assumes you are not using a library.
	// It can be made less wordy if you use one.

    const provideForm = newWindowOrFormElement instanceof HTMLFormElement;
    let form;
    if(provideForm) {
        form = newWindowOrFormElement
    } else {
        const document = window.document;
        var target = '_self';
        var method = 'post';
        form = document.createElement('form');
        form.setAttribute('method', method);
        form.setAttribute('action', path);
        if (newWindowOrFormElement === true) {
            form.setAttribute('target', '_blank');
        }else{
            form.setAttribute('target', '_self');
        }
    }



	if(_passport) {
        _passport
            .getAccessToken(true)
            .catch((error) => {
                _passport.passportRuntimeOptions.redirectToLogin(error)
                throw error
            })
            .then(afterPrepareToken)
    } else {
        afterPrepareToken();
    }

	function afterPrepareToken() {
        const authToken = fastReadAuthToken();
        if (authToken) {
            form.setAttribute('action', addMultipleParams(path || form.getAttribute('action'), { jwt: authToken }));
        }
        loopParams(params, function (key, value) {
            apendFormInput(form, key, value)
        });

        if(provideForm) {
            form.submit();

            loopParams(params, function (key, value) {
                try{
                    removeInputElementByKey(key, form);
                }catch(err) {
                    console.error(err);
                }
            });
        } else {
            window.document.body.appendChild(form);
            form.submit();
            form.parentNode.removeChild(form);
        }
    }
}

function loopParams(params, everyCall) {
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            // const pValue = params[key];
            if (Array.isArray(params[key])) {
                params[key].forEach((value) => {
                    everyCall(key, value);
                });
            } else {
                const pValue = params[key];
                everyCall(key, pValue);
            }
        }
    }
}


function apendFormInput(form, key, value) {
    const simpleFlag = document.createElement('input');
    simpleFlag.setAttribute('type', 'hidden');
    simpleFlag.setAttribute('name', key);
    simpleFlag.setAttribute('value', value);
    form.appendChild(simpleFlag);
}

function removeInputElementByKey(key, form) {
    const list = form.querySelectorAll(`[name=${key}]`);
    for(let i = list.length - 1; 0 <= i; i--){
        if(list[i] && list[i].parentElement){
            list[i].parentElement.removeChild(list[i]);
        }
    }
}

export function setPostByFormPassport(passport) {
    if(passport && typeof passport.exchangeRefreshForAccess === "function" && passport.passportRuntimeOptions) {
        _passport = passport;
    } else {
        console.error(new TypeError(`setPassport in 'postByForm' param type error, 
        need @patsnap-utils/passport > 0.6`))
    }
}
