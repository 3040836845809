import {Observable, Operator, Subject, Subscription} from 'rxjs';
import { switchMap, share, take } from "rxjs/operators";
import { psHttp$ } from './main';
import { RequestConfig } from '../types/subs';

const defRequestAsPromise = {autoUnsub:true, newSubscriptionCallback: (subscription: Subscription) => null};

export default class RequestChannel<Resp, C=RequestConfig> {
    private _asyncCallback: (arg0: C) => Observable<Resp>|PromiseLike<Resp>;
    subject: Subject<unknown>;
    private _prePipes: (typeof switchMap)[];
    private _flatOperator: typeof switchMap;
    private req$: Observable<unknown>;
    constructor(asyncCallback = psHttp$){
        this._asyncCallback = asyncCallback;
        this.subject = new Subject();
        this._prePipes = [];
        this._flatOperator = switchMap;
        this.setReq$();
    }

    static toPromise(obs$, newSubscriptionCallback = (sub: Subscription) => null) {
        return new Promise((resolve, reject) => {
                let subscription = obs$
                    .pipe(
                        take(1)
                    )
                    .subscribe({next: resolve, error: reject});
                newSubscriptionCallback(subscription);
            }
        );
    }

    get flatOperator () {
        return this._flatOperator
    }

    /**
     * 拍平方法
     * @param {Function} operator 前置的pipe
     */
    set flatOperator(operator: typeof switchMap) {
        this._flatOperator = operator;
        this.setReq$();
    }

    get prePipes (): typeof switchMap[] {
        return this._prePipes
    }

    /**
     * 推入请求
     * @param {Array} pipeArray 前置的pipe
     */
    set prePipes(pipeArray:Operator<any, any>[]) {
        this._prePipes = [].concat(pipeArray);
        this.setReq$();
    }

    setReq$() {
        this.req$ = this.subject
            .pipe(
                // @ts-ignore
                ...(this.prePipes || []),
                this.flatOperator((reqConfig:C) => this._asyncCallback(reqConfig)),
                share()
            )
    }

    /**
     * 推入请求
     * @param {Object||Function} conf psHttp配制
     */
    add(conf){
        this.subject.next(conf);
    }

    /**
     * 订阅
     * @param {Function} next 获取数据的回调
     * @param {Function} error  请求/Promise出错的回调
     * @return {Subscription} subscription Subscription object
     */
    sub(next, error) {
        return this.req$
            .pipe(take(1))
            .subscribe({next, error});
    }
    requestAsPromise(reqConf:RequestConfig|C, opt:{autoUnsub?: boolean}={}): Promise<Resp> {
        const _opt = Object.assign({}, defRequestAsPromise, opt);
        return new Promise((resolve, reject) => {
            let subscription = _opt.autoUnsub ?
                this.sub(resolve, reject) :
                this.req$
                    .subscribe({next: resolve, error: reject});
            _opt.newSubscriptionCallback(subscription);
            this.add(reqConf)
        })
    }
}
