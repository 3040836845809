export default {
    PtUserCenter: {
        login: 'Login',
        logout: 'Logout',
        account: 'Account',
        adminSettings: 'Admin Settings',
        team: 'Team',
        projectTimer: 'Project Timer',
        personal: 'Personal',
        enterprise: 'Enterprise',
    },
};
