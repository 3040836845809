var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        "pz-help-center-guider": true,
        "pz-help-center-guider--hidden": !_vm.linkList,
      },
    },
    [
      _c("div", { staticClass: "pz-help-center-guider__title" }, [
        _vm._v(" " + _vm._s(this.$t("guider.title")) + " "),
      ]),
      _c(
        "div",
        { staticClass: "pz-help-center-guider__content" },
        _vm._l(_vm.linkList, function (item, index) {
          return _c(
            "a",
            {
              key: index,
              staticClass: "pz-help-center-guider__link-item",
              attrs: { target: "_blank", href: item.href },
            },
            [_vm._v(" " + _vm._s(item.label) + " ")]
          )
        }),
        0
      ),
      _vm.linkMore
        ? _c(
            "div",
            { staticClass: "pz-help-center-guider__footer" },
            [
              _c(
                "a",
                {
                  staticClass: "pz-help-center-guider__more",
                  attrs: { href: _vm.linkMore.href, target: "_blank" },
                },
                [_vm._v(_vm._s(_vm.linkMore.label))]
              ),
              _c("PtIcon", {
                staticClass: "pz-help-center-guider__more-icon",
                attrs: { icon: "SolidArrowTailRight" },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }