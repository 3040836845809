export default {
    PtTenants: {
        switchTenantTitle: '確定切換組織並刷新頁面嗎？',
        switchTenantTip: '若您有正在編輯的內容，在點擊“確定”後將不會被保存。請問是否繼續切換組織？',
        ok: '確定',
        cancel: '取消',
        personalTenant: '個人賬號',
        domainLimit: '域名限制',
        IPLimit: 'IP限制',
        ssoLimit: 'SSO限制',
        domainLimitTip: '您所在的組織要求您使用商務郵箱訪問智慧芽，請通過郵箱登錄。',
        IPLimitTip: '請在指定的IP範圍內訪問該產品。<br>您所在的組織希望您在指定地點（ 如辦公室） 訪問我們的產品。',
        dingdingTip: '您的管理員要求您通過釘釘的智慧芽應用登錄。',
        feishuTip: '您的管理員要求您通過飛書的智慧芽應用登錄。',
        wecomTip: '您的管理員要求您通過企業微信的智慧芽應用登錄。',
        jwtTip: '您的管理員要求您通過內部認證服務器的智慧芽應用登錄。',
        ssoTip: '您的管理員要求您通過SSO方式登錄智慧芽產品。請通過您的內部認證服務器來訪問智慧芽。',
        switchTenantFailed: '組織切換失敗，請聯繫智慧芽客服人員。',
        accountStatusNotActiveTip: '您的郵箱未設定或未認證，請去用戶設置-個人資訊中認證郵箱。',
        youWillSwitchedTo: '您即將切換到：{tenantName}。',
        error31623: '對不起，為了您組織的數據安全，採用SSO-JWT對接方式則無法切換組織。',
        error31626: '對不起，您組織要求SSO登錄，無法切換到其他組織。',
    },
};
