export default {
    PtTenants: {
        switchTenantTitle: '組織を切り替えてページを更新してもいいですか？',
        switchTenantTip: '「確定」をクリックすると、まだ編集中の操作は保存されませんが、やはり組織を切り替えますか？',
        ok: '確定',
        cancel: 'キャンセル',
        personalTenant: '個人アカウント',
        domainLimit: 'ドメイン制限',
        IPLimit: 'IP 制限',
        ssoLimit: 'SSO の制限',
        domainLimitTip: 'ご所属の組織では、ビジネス用メールアドレスを用いてPatSnapにアクセスすることを要求されていますため、メールアドレスでログインしてください。',
        IPLimitTip: '指定されたIP範囲内で本製品にアクセスしてください。<br>御社はセキュリティ上の理由により、 弊社の製品にアクセスする場所を限定しています（ 例えば、 御社のオフィス）。',
        dingdingTip: '管理者の要求により、 DingTalk方式でPatsnap製品にログインする必要があります。',
        feishuTip: '管理者の要求により、 Feishu方式でPatsnap製品にログインする必要があります。',
        wecomTip: '管理者の要求により、 WeCom方式でPatsnap製品にログインする必要があります。',
        jwtTip: '管理者の要求により、 SSO方式でPatsnap製品にログインする必要があります。',
        ssoTip: '管理者の要求により、SSO方式でPatSnap製品にログインする必要があります。内部認証サーバーを介してスマートバッドにアクセスしてください。',
        switchTenantFailed: '組織の切り替えに失敗しました。PatSnapのカスタマーサービスにお問い合わせください。',
        accountStatusNotActiveTip: 'あなたのメールアドレスは検証されていません。アカウント-個人情報に移動してメール検証を完了してください。',
        youWillSwitchedTo: 'あなたは {tenantName} に切り替えようとしています。',
        error31623: '申し訳ありませんが、組織のデータセキュリティのため、SSO-JWTドッキングを使用して組織を切り替えることはできません。',
        error31626: '申し訳ありませんが、あなたの組織はSSOログインが必要で、他の組織に切り替えることはできません。',
    },
};
