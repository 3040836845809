export default {
    PtUserCenter: {
        login: '登錄',
        logout: '登出',
        account: '用戶設置',
        adminSettings: '管理員工作臺',
        team: '團隊',
        projectTimer: '項目計時',
        language: '語言',
        personal: '個人',
        enterprise: '企業',
    },
};
