var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ElDialog",
    _vm._g(
      _vm._b(
        {
          ref: "dialog",
          staticClass: "patsnap-biz-dialog",
          scopedSlots: _vm._u(
            [
              {
                key: "title",
                fn: function () {
                  return [
                    _vm._t("title", function () {
                      return [
                        _c("span", { staticClass: "el-dialog__title" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                      ]
                    }),
                    _vm.showClose
                      ? _c("PtIcon", {
                          staticClass: "patsnap-biz-dialog__close-btn",
                          attrs: { icon: "SolidCloseBig" },
                          on: { click: _vm.handleClose },
                        })
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
              {
                key: "default",
                fn: function () {
                  return [_vm._t("default")]
                },
                proxy: true,
              },
              {
                key: "footer",
                fn: function () {
                  return [_vm._t("footer")]
                },
                proxy: true,
              },
            ],
            null,
            true
          ),
        },
        "ElDialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }