import {FetchConfig, RequestConfig} from "../../types/subs";
import FetchAdapterClass from "../adapters/FetchAdapterClass";
import {version} from "../../package.json";

let forceNetworkOnly = false;

export enum PersistentCacheStrategy {
    CACHE_ONLY = 'CACHE_ONLY',
    NETWORK_ONLY = 'NETWORK_ONLY',
    CACHE_FIRST = 'CACHE_FIRST',
    CACHE_FIRST_WITH_SILENT_UPDATE = 'CACHE_FIRST_WITH_SILENT_UPDATE',
}


class PermCacheFetchAdapter extends FetchAdapterClass {
    private cacheStorage: Cache;
    storagePrefix: string;

    constructor(private readonly persistentCacheConfig: PersistentCacheConfig) {
        super();
        this.storagePrefix = persistentCacheConfig.storagePrefix;
        if (!this.storagePrefix) {
            throw new Error('storagePrefix key is required');
        }
    }

    async getCacheStorageAsync() {
        if (this.cacheStorage) {
            return this.cacheStorage;
        }
        try{
            this.cacheStorage = await caches.open(this.storagePrefix); // Firefox隐身模式会报错
        } catch (e) {
            forceNetworkOnly = true; // 下次用network only
            throw e;
        }
        return this.cacheStorage;
    }

    static getRequestVaryHeaderKeys(request: Request) {
        const varyHeader = request.headers.get('Vary');
        if (!varyHeader) {
            return [];
        }
        return varyHeader.split(',').map(s => s.trim()).filter(s => s);
    }

    static async matchCache(cacheStorage: Cache, request: Request): Promise<Response | null> {
        const cachedResponse = await cacheStorage.match(request, {
            ignoreVary: false,
            ignoreSearch: false,
            ignoreMethod: false
        });
        if (!cachedResponse) {
            return null;
        }
        const matched = PermCacheFetchAdapter.getRequestVaryHeaderKeys(request).every(headerKey => {
            return request.headers.get(headerKey) === cachedResponse.headers.get(`X-Pshttp-Cache-Vary-${headerKey}`)
        })
        if (!matched) {
            console.debug(`Cache is not match by Vary Headers, deleting:`, cachedResponse);
            await cacheStorage.delete(request);
        }
        return matched ? cachedResponse : null;
    }

    async requestMethod(request: Request, config): Promise<Response> {
        const self = this;

        async function getCacheResponse() {
            try {
                const cacheStorage = await self.getCacheStorageAsync();
                const cachedResponse = await PermCacheFetchAdapter.matchCache(cacheStorage, request);

                if (cachedResponse) {
                    if (await self.persistentCacheConfig.detectCacheUsableHook(cachedResponse, request)) {
                        console.log('Use cache: ', cachedResponse);
                        return cachedResponse.clone();
                    } else {
                        console.debug(`[${self.storagePrefix}] Cache is not usable, deleting`);
                        await cacheStorage.delete(request);
                    }
                } else {
                    await cacheStorage.delete(request);
                }
            } catch (e) {
                console.warn(e);
                return null;
            }

        }


        async function requestAndUpdateCache(): Promise<Response> {
            const response = await fetch(request);

            const cacheResponse = response.clone();
            // 不新建会只克隆丢失附加头
            const _responseClone = new Response(cacheResponse.body, cacheResponse);

            // 根据Vary header存储到response里， 以验证缓存是否匹配
            PermCacheFetchAdapter.getRequestVaryHeaderKeys(request).forEach(headerKey => {
                const reqValue = request.headers.get(headerKey);
                if (reqValue) {
                    _responseClone.headers.append(`X-Pshttp-Cache-Vary-${headerKey}`, reqValue);
                }
            })
            if (await self.persistentCacheConfig.detectAllowCachingHook(_responseClone)) {
                try{
                    const cacheStorage = await self.getCacheStorageAsync();
                    await cacheStorage.put(request, _responseClone);
                    console.debug(`[${self.storagePrefix}] Cache is updated:`, _responseClone);
                }catch (e) {
                    console.warn(e);
                }
            }

            return response
        }


        switch (this.persistentCacheConfig.strategy) {
            case PersistentCacheStrategy.CACHE_ONLY: {
                const cachedResponse = await getCacheResponse();
                return cachedResponse || new Response('psHttp Cache not hit', {status: 404});
            }
            case PersistentCacheStrategy.NETWORK_ONLY: {
                return fetch(request);
            }
            case PersistentCacheStrategy.CACHE_FIRST: {
                const cachedResponse = await getCacheResponse();
                if (cachedResponse) {
                    return cachedResponse;
                }
                return requestAndUpdateCache();
            }
            case PersistentCacheStrategy.CACHE_FIRST_WITH_SILENT_UPDATE: {
                const cachedResponse = await getCacheResponse();
                if (cachedResponse) {
                    // 故意不await
                    requestAndUpdateCache()
                        .then(newResp => self.persistentCacheConfig.afterSilentCacheUpdateCompareHook(newResp, cachedResponse));
                    return cachedResponse;
                }
                return requestAndUpdateCache();
            }
        }
    }
}

export class PersistentCacheConfig {
    storagePrefix?: string;
    strategy?: PersistentCacheStrategy;
    detectAllowCachingHook?: (response: Response) => boolean | Promise<boolean>;
    detectCacheUsableHook?: (response: Response, historicalRequest: Request) => boolean | Promise<boolean>;
    afterSilentCacheUpdateCompareHook?: (newResponse: Response, historicalResponse: Response) => void | Promise<void>;
    debug?: boolean = window.location.search.includes('noraven');
    VaryHeadersArray?: string[] = ['Accept-Language', 'Authorization', 'x-api-version'];

    constructor(config: PersistentCacheConfig) {
        this.storagePrefix = config.storagePrefix || `pshttp-cache@${version}`;
        this.strategy = config.strategy || PersistentCacheStrategy.CACHE_FIRST;
        if (this.debug || !('caches' in window)) {
            this.strategy = PersistentCacheStrategy.NETWORK_ONLY;
        }

        function passOk(res: Response) {
            return res.ok
        }

        this.detectCacheUsableHook = config.detectCacheUsableHook ? (res: Response, historicalRequest: Request) => config.detectCacheUsableHook(res.clone(), historicalRequest) : passOk;
        this.detectAllowCachingHook = config.detectAllowCachingHook ? (res: Response) => config.detectAllowCachingHook(res.clone()) : passOk;

        if (config.afterSilentCacheUpdateCompareHook) {
            this.afterSilentCacheUpdateCompareHook = (newResponse: Response, historicalResponse: Response) => config.afterSilentCacheUpdateCompareHook(newResponse.clone(), historicalResponse.clone())
        } else {
            this.afterSilentCacheUpdateCompareHook = (newResponse: Response, historicalResponse: Response) => undefined;
        }

        if (Array.isArray(config.VaryHeadersArray)) {
            this.VaryHeadersArray = config.VaryHeadersArray;
        }
    }

    static defaults = {
        detectAllowCachingHook_statusdata(resp): Promise<boolean> {
            if (!resp.ok) {
                return Promise.resolve(false)
            }

            // 返回内容是status + data的模式的话，强烈建议要有这句！！
            return resp.json().then(body => {
                return body.status && body.data;
            }).catch(err => false);
        }
    }

}


const isCacheStorageSupported = ('fetch' in window) && ('caches' in window) && ('CacheStorage' in window) && ('match' in window.CacheStorage.prototype);

export function requestConfigWrapper(config: RequestConfig, persistentCacheConfig: PersistentCacheConfig): FetchConfig | RequestConfig {

    if (!isCacheStorageSupported) {
        console.warn('CacheStorage is not supported, fallback to network only');
        return config;
    }
    if(forceNetworkOnly) {
        return config;
    }

    const _conf = {...config};
    const originHeaders = {...(config.headers || {})}
    _conf.headers = {
        ...originHeaders,
        Vary: originHeaders.Vary || originHeaders.vary || persistentCacheConfig.VaryHeadersArray.join(','),
    };
    if (config.timeout) {
        console.warn('[FetchAdapter] timeout is not supported yet');
    }
    delete _conf.timeout
    delete _conf.cache

    const _adapterInst = new PermCacheFetchAdapter(persistentCacheConfig);

    return {
        ..._conf,
        adapter: _adapterInst.adapter.bind(_adapterInst),
        cache: 'no-cache',
    } as FetchConfig;
}