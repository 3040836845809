var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.tenantDropdownVisible
        ? _c("PtHeaderDropdown", {
            staticClass: "patsnap-biz-tenants",
            attrs: { trigger: _vm.$attrs.trigger },
            scopedSlots: _vm._u(
              [
                {
                  key: "reference",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "patsnap-biz-tenants__title" },
                        [
                          _c("PtHeaderWidget", {
                            staticClass: "patsnap-biz-tenants__widget",
                            attrs: {
                              label: _vm.activeValue,
                              "post-icon":
                                _vm.items.length > 1 ? "SolidChangeOrder" : "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "popper",
                  fn: function () {
                    return [
                      _c("PtList", {
                        staticClass: "patsnap-biz-tenants__popper",
                        attrs: { items: _vm.items },
                        on: { itemClick: _vm.handleItemClick },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var item = ref.item
                                return [
                                  _c(
                                    "PtTooltip",
                                    {
                                      attrs: {
                                        disabled: !item.disabled,
                                        effect: "light",
                                        placement: "left",
                                        offset: "8",
                                        "visible-arrow": false,
                                        "popper-class":
                                          "patsnap-biz-tenants__tooltip",
                                      },
                                    },
                                    [
                                      _c("div", {
                                        attrs: { slot: "content" },
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.getTooltip(item)
                                          ),
                                        },
                                        slot: "content",
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "patsnap-biz-tenants__list-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "patsnap-ui-list-item__content",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.label)),
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "patsnap-biz-tenants__tags",
                                                },
                                                [
                                                  item.domain_restrict
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "patsnap-biz-tenants__tag",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PtTenants.domainLimit"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  item.ip_restrict
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "patsnap-biz-tenants__tag",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PtTenants.IPLimit"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  item.sso_restrict
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "patsnap-biz-tenants__tag",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "PtTenants.ssoLimit"
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                          item.postIcon
                                            ? _c("PtIcon", {
                                                staticClass:
                                                  "patsnap-ui-list-item__post-icon",
                                                attrs: { icon: item.postIcon },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          47791658
                        ),
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1534498404
            ),
          })
        : _vm._e(),
      _vm.tenantTitleVisible
        ? _c("div", { staticClass: "patsnap-biz-tenants__title" }, [
            _c("div", { staticClass: "patsnap-biz-tenants__title-content" }, [
              _vm._v(" " + _vm._s(_vm.activeValue) + " "),
            ]),
          ])
        : _vm._e(),
      _vm.service.switchTenantDialogVisible
        ? _c(
            "PtDialog",
            {
              staticClass: "patsnap-biz-tenants__switch-dialog",
              attrs: {
                title: _vm.$t("PtTenants.switchTenantTitle"),
                visible: _vm.service.switchTenantDialogVisible,
                "append-to-body": true,
                "close-on-click-modal": false,
                "close-on-press-escape": false,
                width: "400px",
              },
              on: {
                "update:visible": function ($event) {
                  return _vm.$set(
                    _vm.service,
                    "switchTenantDialogVisible",
                    $event
                  )
                },
              },
            },
            [
              _c("div", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("PtTenants.youWillSwitchedTo", {
                        tenantName: _vm.selectedCurrentTenant.label,
                      })
                    )
                ),
                _c("br"),
                _vm._v(" " + _vm._s(_vm.$t("PtTenants.switchTenantTip")) + " "),
              ]),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "pt-ui-btn",
                      attrs: { "data-size": "medium", disabled: _vm.loading },
                      on: {
                        click: function ($event) {
                          _vm.service.switchTenantDialogVisible = false
                        },
                      },
                    },
                    [_c("span", [_vm._v(_vm._s(_vm.$t("PtTenants.cancel")))])]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "pt-ui-btn patsnap-biz-tenants__switch-dialog__confirm-btn",
                      attrs: {
                        "data-type": "primary",
                        "data-size": "medium",
                        disabled: _vm.loading,
                      },
                      on: { click: _vm.handleOk },
                    },
                    [
                      _c("span", { style: { opacity: _vm.loading ? 0 : 1 } }, [
                        _vm._v(_vm._s(_vm.$t("PtTenants.ok"))),
                      ]),
                      _vm.loading
                        ? _c("PtIcon", { attrs: { icon: "SolidLoadingBars" } })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }