import Vue from 'vue';
import { PopupManager } from 'element-ui/lib/utils/popup';
import { isVNode } from 'element-ui/lib/utils/vdom';
import Component from './Toast.vue';

const ToastComponent = Vue.extend(Component);

let instance;
const instances = [];
let seed = 1;

export default function Toast(options = {}) {
    /* istanbul ignore next */
    if (Vue.prototype.$isServer) return null;

    const userOnClose = options.onClose;
    seed += 1;
    const id = `toast_${seed}`;

    instance = new ToastComponent({
        data: {
            ...(typeof options === 'string' ? { message: options } : options),
            onClose() {
                Toast.close(id, userOnClose);
            },
        },
    });
    instance.id = id;

    if (isVNode(instance.message)) {
        instance.$slots.default = [instance.message];
        instance.message = null;
    }

    instance.vm = instance.$mount();
    document.body.appendChild(instance.vm.$el);
    instance.vm.visible = true;
    instance.dom = instance.vm.$el;
    instance.dom.style.zIndex = PopupManager.nextZIndex();
    instances.push(instance);

    return instance.vm;
}

['success', 'warning', 'info', 'error'].forEach((type) => {
    Toast[type] = (options) => {
        if (typeof options === 'string') {
            return Toast({
                message: options,
                type,
            });
        }
        return Toast({
            ...options,
            type,
        });
    };
});

Toast.close = (id, userOnClose) => {
    for (let i = 0, len = instances.length; i < len; i += 1) {
        if (id === instances[i].id) {
            if (typeof userOnClose === 'function') {
                userOnClose(instances[i]);
            }
            instances.splice(i, 1);
            break;
        }
    }
};

Toast.closeAll = () => {
    for (let i = instances.length - 1; i >= 0; i -= 1) {
        instances[i].close();
    }
};

export function install(GlobalVue) {
    /* eslint-disable no-param-reassign */
    GlobalVue.toast = Toast;
    GlobalVue.prototype.$toast = Toast;
}
