var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "patsnap-biz-avatar",
      style: {
        backgroundImage: "url(" + _vm.avatar + ")",
        backgroundSize: "contain",
        backgroundColor: _vm.backgroundColor,
      },
    },
    [
      !_vm.done && !_vm.error && _vm.name
        ? _c("span", [_vm._v(_vm._s(_vm.name))])
        : _vm._e(),
      _vm.error || _vm.noAvatar
        ? _c("PtIcon", {
            staticClass: "patsnap-biz-avatar__error",
            attrs: { icon: "SolidProfile" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }