export default {
    PtHeader: {
        help: 'ヘルプ', // ヘルプ
        helpCenter: 'ヘルプセンター', // ヘルプセンター
        quickStartGuide: '初心者向けガイド', // 初心者向けガイド
        gettingStartedVideos: '入門ビデオ', // 入門ビデオ
        searchHelper: '検索ヘルプ', // 検索ヘルプ
        releaseLog: 'リリース履歴', // リリース履歴
        dataCoverage: 'データ収録範囲', // データ収録範囲
        askForHelp: 'お問合せ', // お問合せ
        products: '製品',
        workspace: 'Workspace',
        marketPlace: 'Marketplace',
        analytics: 'Analytics',
        insights: 'Insights',
        bio: 'Bio',
        chemical: 'Chemical',
        discovery: 'Discovery',
        synapse: 'Synapse',
        academy: 'Academy',
        course: 'Academy',
        course2b: 'Course',
        lab: 'PatSnap Lab',
        open: 'Data Service',
        ipAsset: 'IP Asset',
        allProducts: '製品',
        tffi: 'TFFI',
        eureka: 'Eureka',
        pharmsnap: 'PharmSnap',
    },
};
