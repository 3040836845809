import {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {getFullUrlByConf} from "./getFullUrl";
/**
 * 加密算法的版本
 * 由于前后使用的是对称加密算法,为了在同时多套算法共存情况下保证加密算法的一致性
 */
export const SIGN_VERSION = "0.1";

function defaultSign(url: string,ts: number) {
    return `${url}${ts}`;
}

interface RequestSignInterceptorOptions {
    /**
     * 存储时间偏移量的LocalStorage的key
     */
    timeOffsetStorageKey?: string;
    /**
     * 是否需要拦截200的请求
     */
    interceptorSuccessResponse?: boolean;
    /**
     * 决定是否需要添加请求签名
     */
    detectShouldSignRequest?: (config: AxiosRequestConfig, fullUrl:string) => boolean;
    /**
     * 决定是否需要拦截错误的请求
     */
    detectResponseShouldRefresh: (response: AxiosResponse | AxiosError) => boolean;
    /**
     * 获取服务器的时间戳(毫秒)
     */
    getServiceTimeStamp: () => Promise<number>;
    /**
     * 获取服务器的时间戳报错
     */
    onGetServiceTimeStampError?: (error?: any) => void;
}

class RequestSignInterceptor {
    /**
     * 时间偏移量
     */
    private _timeOffset: number = 0;
    /**
     * 是否在刷新时间
     */
    private isRefreshIng: boolean = false;
    /**
     * 刷新时间任务
     */
    private refreshTimePromise: Promise<any> = Promise.resolve();

    /**
     * 获取偏移量
     */
    private get timeOffset(): number {
        return this._timeOffset;
    }

    /**
     * 设置偏移量
     */
    private set timeOffset(value: number) {
        this._timeOffset = value;
        this.setLocalTimeOffset(this._timeOffset);
    }

    /**
     * performance.now() 不会因为使用过程中用户操作系统时间同步突然完成而发生大幅度突变，Date.now() 会
     */
    private _supposedAccurateTimeBase = Date.now();
    private _timebaseOffset = prefNow();
    set supposedAccurateTimeBase(ts:number) {
        this._supposedAccurateTimeBase = ts;
        this._timebaseOffset = prefNow();
    }
    get supposedAccurateTime(): number {
        return this._supposedAccurateTimeBase + (prefNow() - this._timebaseOffset);
    }

    /**
     * 存储时间偏移量的LocalStorage的key
     */
    private get timeOffsetStorageKey() {
        return this.options.timeOffsetStorageKey || "patsnap-time-offset";
    }

    constructor(public options: RequestSignInterceptorOptions) {
        this._timeOffset = this.getLocalTimeOffset();
        this.supposedAccurateTimeBase = Date.now() + this.timeOffset;
    }

    /**
     * 请求拦截器
     * @param config 请求配置
     * @returns
     */
    public async requestInterceptor(config: AxiosRequestConfig) {
        if (this.isRefreshIng) await this.refreshTimePromise;

        const fullUrl = getFullUrlByConf(config);

        if (this.options.detectShouldSignRequest && !this.options.detectShouldSignRequest(config, fullUrl)) {
            return config;
        }

        try {
            const XTs = this.supposedAccurateTime;
            const XSign = await this.sign(fullUrl, XTs);
            config.headers = {
                ...config.headers || {},
                "x-t": XTs,
                "x-p-s": XSign,
                // "x-sign-version": SIGN_VERSION,
            };
        } catch (error) {
            console.error(error);
        }
        return config;
    }

    async sign(url: string, ts: number): Promise<string> {
        return defaultSign(url, ts);
    }

    /**
     * 响应拦截器
     * @param response 响应
     * @param requester 请求器
     * @returns
     */
    public async responseInterceptor(requester: AxiosInstance, response: AxiosResponse) {
        if (this.options.detectResponseShouldRefresh(response)) {
            await this.syncServiceTime();
            return requester.request(response.config);
        }
        return response;
    }
    public async responseErrorInterceptor(requester: AxiosInstance, error: AxiosError) {
        if (this.options.detectResponseShouldRefresh(error)) {
            await this.syncServiceTime();
            return requester.request(error.config);
        }
        return Promise.reject(error);
    }



    /**
     * 同步服务器时间
     */
    public syncServiceTime(): Promise<void> {
        this.isRefreshIng = true;
        this.refreshTimePromise = this.options
            .getServiceTimeStamp()
            .then((timestamp) => {
                this.supposedAccurateTimeBase = timestamp;
                this.timeOffset = timestamp - Date.now();
            })
            .catch((error) => this.options.onGetServiceTimeStampError && this.options.onGetServiceTimeStampError(error))
            .finally(() => {
                this.isRefreshIng = false;
            });
        return this.refreshTimePromise;
    }

    /**
     * 获取时间偏移量
     * @returns number
     */
    private getLocalTimeOffset(): number {
        try {
            const stringValue = localStorage.getItem(this.timeOffsetStorageKey);
            if (!stringValue) {
                return 0;
            }
            return Number(stringValue);
        } catch (error) {
            return 0;
        }
    }

    /**
     * 存储时间偏移
     * @param value 时间戳
     */
    private setLocalTimeOffset(value: number) {
        try {
            localStorage.setItem(this.timeOffsetStorageKey, `${value}`);
        } catch (error) {
            console.error(error);
        }
    }
}

export function registerRequestSignInterceptor(axiosInstance: AxiosInstance, options: RequestSignInterceptorOptions) {
    const requestSignInterceptor = new RequestSignInterceptor(options);
    const requestInterceptor = requestSignInterceptor.requestInterceptor.bind(requestSignInterceptor);
    const responseInterceptor = requestSignInterceptor.responseInterceptor.bind(requestSignInterceptor, axiosInstance);
    const responseErrorInterceptor = requestSignInterceptor.responseErrorInterceptor.bind(requestSignInterceptor, axiosInstance);
    axiosInstance.interceptors.request.use(requestInterceptor);
    axiosInstance.interceptors.response.use(options.interceptorSuccessResponse ? responseInterceptor : undefined, responseErrorInterceptor);
    return requestSignInterceptor;
}


function prefNow(): number {
    return Math.floor(performance.now());
}