export default {
    PtHeader: {
        help: '帮助', // ヘルプ
        helpCenter: '帮助中心', // ヘルプセンター
        quickStartGuide: '新手入门', // 初心者向けガイド
        gettingStartedVideos: '入门视频', // 入門ビデオ
        searchHelper: '搜索帮助', // 検索ヘルプ
        releaseLog: '最新功能', // リリース履歴
        dataCoverage: '数据范围', // データ収録範囲
        askForHelp: '需要帮助', // お問合せ
        products: '产品',
        workspace: '工作空间',
        marketPlace: '导航库中心',
        analytics: '专利数据库',
        insights: '英策',
        bio: 'Bio',
        chemical: 'Chemical',
        discovery: 'Discovery',
        synapse: '新药情报库',
        academy: '智慧芽学社',
        course: '智慧芽学社',
        course2b: '企业版学社',
        lab: 'PatSnap Lab',
        open: '数据服务平台',
        ipAsset: '资产管理',
        allProducts: '产品',
        tffi: 'TFFI',
        eureka: 'Eureka',
        pharmsnap: 'PharmSnap',
    },
};
