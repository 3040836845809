var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Transition", { attrs: { name: "pt-message-fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.visible,
            expression: "visible",
          },
        ],
        class: [
          "patsnap-biz-toast",
          "patsnap-biz-toast--" + _vm.type,
          "patsnap-biz-toast--" + _vm.size,
          {
            "patsnap-biz-toast--closable": _vm.showClose,
            "patsnap-biz-toast--without-icon": !_vm.iconName,
          },
        ],
        attrs: { role: "alert" },
        on: { mouseenter: _vm.clearTimer, mouseleave: _vm.startTimer },
      },
      [
        _vm.iconName
          ? _c("PtIcon", {
              style: { fontSize: _vm.iconSize },
              attrs: { icon: _vm.iconName },
            })
          : _vm._e(),
        _vm._t("default", function () {
          return [
            !_vm.dangerouslyUseHTMLString
              ? _c("p", { staticClass: "patsnap-biz-toast__content" }, [
                  _vm._v(" " + _vm._s(_vm.message) + " "),
                ])
              : _c("p", {
                  staticClass: "patsnap-biz-toast__content",
                  domProps: { innerHTML: _vm._s(_vm.message) },
                }),
          ]
        }),
        _vm.showClose
          ? _c(
              "span",
              { staticClass: "patsnap-biz-toast__close-btn" },
              [
                _c("PtIcon", {
                  style: { fontSize: _vm.iconSize },
                  attrs: { icon: "SolidCloseMedium" },
                  on: { click: _vm.close },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }