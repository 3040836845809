var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.hidePopper,
          expression: "hidePopper",
        },
      ],
      staticClass: "patsnap-biz-header-dropdown",
      class:
        ((_obj = {}),
        (_obj[_vm.$attrs.align || "right"] = true),
        (_obj["patsnap-biz-header-dropdown--opened"] = _vm.visible),
        _obj),
    },
    [
      _c(
        "div",
        {
          staticClass: "patsnap-biz-header-dropdown__reference",
          on: {
            mouseenter: _vm.showPopperImmediate,
            mouseleave: _vm.hidePopperSlowly,
            click: _vm.handleClick,
          },
        },
        [
          _vm._t("reference", null, {
            open: _vm.visible,
            close: _vm.hidePopper,
          }),
        ],
        2
      ),
      _vm.$slots.popper || _vm.$scopedSlots.popper
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.visible,
                  expression: "visible",
                },
              ],
              ref: "popper",
              staticClass: "patsnap-biz-header-dropdown__popper",
              on: {
                mouseenter: _vm.showPopperImmediate,
                mouseleave: _vm.hidePopperSlowly,
              },
            },
            [
              _vm._t("popper", null, {
                open: _vm.visible,
                close: _vm.hidePopper,
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }