import { AxiosError, AxiosResponse } from 'axios';
import { FetchConfig } from "../../types/subs";

const settle = require('axios/lib/core/settle');
const buildURL = require('axios/lib/helpers/buildURL');
const buildFullPath = require('axios/lib/core/buildFullPath');
const {isUndefined} = require('axios/lib/utils');
const createError = require('axios/lib/core/createError')


export default class FetchAdapterClass {
    constructor() {
    }


    /**
     * - Create a request object
     * - Get response body
     * - Check if timeout
     */
    async adapter(config: FetchConfig): Promise<AxiosResponse | AxiosError> {
        const request = createRequest(config);
        const reqPromise = this.getResponse(request, config);

        const data = await reqPromise;
        return new Promise((resolve, reject) => {
            if (data instanceof Error) {
                reject(data);
            } else {
                // @ts-ignore
                Object.prototype.toString.call(config.settle) === '[object Function]' ? config.settle(resolve, reject, data)
                    : settle(resolve, reject, data);
            }
        });
    }

    requestMethod(request:Request, config): Promise<Response> {
        return fetch(request)
    }

    axiosRespHook(response: AxiosResponse, config: FetchConfig): AxiosResponse {
        return response;
    }

    /**
     * Fetch API stage two is to get response body. This function tries to retrieve
     * response body based on response's type
     */
    async getResponse(request:Request, config): Promise<AxiosResponse | AxiosError> {
        let stageOne;
        try {
            stageOne = await this.requestMethod(request, config);
            stageOne = stageOne.clone();
        } catch (e) {
            console.error(e);
            return createError('Network Error', config, 'ERR_NETWORK', request);
        }
        const axiosHeaders = {};
        stageOne.headers.forEach(function (value, key) {
            axiosHeaders[key] = value;
        });

        const response: AxiosResponse = {
            data: null,
            status: stageOne.status,
            statusText: stageOne.statusText,
            headers: axiosHeaders, // Make a copy of headers
            config: config,
            request,
        };

        if (stageOne.status >= 200 && stageOne.status !== 204) {
            switch (config.responseType) {
                case 'arraybuffer':
                    response.data = await stageOne.arrayBuffer();
                    break;
                case 'blob':
                    response.data = await stageOne.blob();
                    break;
                case 'json':
                    response.data = await stageOne.json();
                    break;
                case 'formData':
                    response.data = await stageOne.formData();
                    break;
                default:
                    response.data = await stageOne.text();
                    break;
            }
        }

        return this.axiosRespHook(response, config);
    }

}

/**
 * This function will create a Request object based on configuration's axios
 */
function createRequest(config: FetchConfig): Request {
    const headers = new Headers({...config.headers} as Record<string, string>);

    // HTTP basic authentication
    if (config.auth) {
        const username = config.auth.username || '';
        const password = config.auth.password ? decodeURI(encodeURIComponent(config.auth.password)) : '';
        headers.set('Authorization', `Basic ${btoa(username + ':' + password)}`);
    }

    const method = config.method.toUpperCase();
    const options: RequestInit = {
        headers: headers,
        method,
    };
    if (method !== 'GET' && method !== 'HEAD') {
        options.body = config.data;
    }
    if (config.mode) {
        options.mode = config.mode;
    }
    if (config.cache) {
        options.cache = config.cache;
    }
    if (config.integrity) {
        options.integrity = config.integrity;
    }
    if (config.redirect) {
        options.redirect = config.redirect;
    }
    if (config.referrer) {
        options.referrer = config.referrer;
    }
    if (config.signal) {
        options.signal = config.signal;
    }
    // This config is similar to XHR’s withCredentials flag, but with three available values instead of two.
    // So if withCredentials is not set, default value 'same-origin' will be used
    if (!isUndefined(config.withCredentials)) {
        options.credentials = config.withCredentials ? 'include' : 'omit';
    }

    const fullPath = buildFullPath(config.baseURL, config.url);
    const url = buildURL(fullPath, config.params, config.paramsSerializer);

    // Expected browser to throw error if there is any wrong configuration value
    return new Request(url, options);
}


/**
 * Note:
 *
 *   From version >= 0.27.0, createError function is replaced by AxiosError class.
 *   So I copy the old createError function here for backward compatible.
 *
 *
 *
 * Create an Error with the specified message, config, error code, request and response.
 *
 * @param {string} message The error message.
 * @param {Object} config The config.
 * @param {string} [code] The error code (for example, 'ECONNABORTED').
 * @param {Object} [request] The request.
 * @param {Object} [response] The response.
 * @returns {Error} The created error.
 */
function old_createError(message, config, code, request, response = undefined): AxiosError {
    const error = new Error(message);
    return enhanceError(error, config, code, request, response);
};

/**
 *
 * Note:
 *
 *   This function is for backward compatible.
 *
 *
 * Update an Error with the specified config, error code, and response.
 *
 * @param {Error} error The error to update.
 * @param {Object} config The config.
 * @param {string} [code] The error code (for example, 'ECONNABORTED').
 * @param {Object} [request] The request.
 * @param {Object} [response] The response.
 * @returns {Error} The error.
 */
export function enhanceError(error, config, code, request, response) {
    error.config = config;
    if (code) {
        error.code = code;
    }

    error.request = request;
    error.response = response;
    error.isAxiosError = true;

    error.toJSON = function toJSON() {
        return {
            // Standard
            message: this.message,
            name: this.name,
            // Microsoft
            description: this.description,
            number: this.number,
            // Mozilla
            fileName: this.fileName,
            lineNumber: this.lineNumber,
            columnNumber: this.columnNumber,
            stack: this.stack,
            // Axios
            config: this.config,
            code: this.code,
            status: this.response && this.response.status ? this.response.status : null
        };
    };
    return error;
};